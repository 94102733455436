<template>
  <div id="app">
    <template v-if="$route.meta.showNavbar">
        <Navbar/>
        <div class="flex main-container">
          <div w="full" py="4">
            <router-view></router-view>
          </div>
        </div>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import Navbar from './components/layouts/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar,
  },
  watch: {
    '$route': function (to, from) {
      document.title = to.meta.title || '微柚工具';
    }
  },
  created() {
    document.title = this.$route.meta.title || '微柚工具';
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  margin: 0;
}
.main-container {
  height: calc(100vh - 60px - 3px);
  overflow-y: auto;
}
</style>
