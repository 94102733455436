import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { showNavbar: true }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { showNavbar: true }
  },
  {
    path: '/vuepress',
    name: 'vuepressTool',
    component: () => import(/* webpackChunkName: "about" */ '../views/vuepress/VuepressTool.vue'),
    meta: { showNavbar: false }
  },
  {
    path: '/blog',
    name: 'blogTool',
    component: () => import(/* webpackChunkName: "about" */ '../views/BlogTool.vue'),
    meta: { showNavbar: false }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 配置 beforeEach 钩子以允许静态资源请求
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0 && to.path.includes('/json/')) {
    next(true); // 允许静态资源请求
  } else {
    next(); // 正常路由处理
  }
});

export default router
