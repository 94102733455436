<template>
  <el-header class="navbar">
    <el-menu
      :default-active="$route.path"
      class="nav-menu"
      mode="horizontal"
      text-color="#000"
      active-text-color="#ffd04b"
      router
    >
      <el-menu-item index="/">首页</el-menu-item>
      <el-menu-item index="/vuepress">笔记工具</el-menu-item>
      <el-menu-item index="/blog">博客工具</el-menu-item>
      <el-menu-item index="/about">关于</el-menu-item>
      <!-- 添加更多的菜单项... -->
    </el-menu>
  </el-header>
</template>

<script>
export default {
  name: 'Navbar',
}
</script>

<style scoped>
.navbar {
  height: 60px;
  line-height: 60px;
  padding: 0;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.nav-menu {
  height: 100%;
}
</style>